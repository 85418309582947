import React from 'react'
import Hero from "../components/Home/Hero"
import About from "../components/Home/About"
import Skills from "../components/Home/Skills"
import Portfolio from "../components/Home/Portfolio"
import Contact from "../components/Home/Contact"
import Blog from "../components/Home/Course"
import Brands from '../components/Home/Brands'
import Cform from '../components/Contact/Cform'



const HomePage = () => {
  
  return (
    <>

      <Hero />
      
        <section class="container in-box">
          <About />
          <Skills />
          <Portfolio />
          <Blog />
          <Brands />
          <Cform />
        
        </section>

    </>
  )
}

export default HomePage
