import {Link} from "react-router-dom"

const About = () => {
  return (
    <>
            <div className='row my-4 d-flex align-items-center'>
            <div className='col-md-6 py-4 px-4'>
            <h6 class="sub-title opacity-7 mb-15">About Me</h6>
                <h3>Meet Yakub Ahmed</h3>
                <p className='text-justify'>
                    I'm a Full Stack Web Developer with a robust background in both front-end and 
                    back-end technologies. With a BSc in Computer Science and over four years of 
                    experience, I specialize in creating high-performance web applications that 
                    enhance user experience. As the Founder & CEO of Xidig Tech, I drive innovation 
                    through cutting-edge software solutions tailored for diverse business needs.
                </p>
                <br />
                <p> Interested in collaborating or learning more? </p>
                <br />
                <div className="butn-presv">
                    <Link to="/about" className="butn-primary butn butn-md butn-bord radius-5 ">
                        <span>Learn more </span>
                    </Link>
                </div>
              
            </div>
            <div className='col-md-6'>
                <img src="assets/imgs/me-about.png" alt="" height='400' style={ { borderRadius: "20px" } } />
            </div>
        </div>
    </>
  )
}

export default About